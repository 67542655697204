import React from "react";
import clsNames from "classnames";

export const ZaloLogoComponent = () => {
  return (
    <svg
      width="47"
      height="17"
      viewBox="0 0 47 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4183 0.219116H0.372731V3.00485H9.43008L0.48455 14.0364C0.205002 14.4264 0 14.8164 0 15.6707V16.3764H12.3001C12.9151 16.3764 13.4183 15.8749 13.4183 15.2621V13.7764H3.93231L12.3187 3.28342C12.4306 3.13485 12.6915 2.85628 12.7847 2.72628L12.8406 2.65199C13.3251 1.94627 13.4183 1.33341 13.4183 0.609119V0.219116Z"
        fill="#006AF5"
      />
      <path
        d="M30.0041 16.3764H31.8677V0.0334167H29.0723V15.4478C29.0723 15.9678 29.4823 16.3764 30.0041 16.3764Z"
        fill="#006AF5"
      />
      <path
        d="M25.3448 16.3763H26.8357V4.15622H24.2266V15.262C24.2266 15.8749 24.7297 16.3763 25.3448 16.3763Z"
        fill="#006AF5"
      />
      <path
        d="M20.5005 3.80353C16.9968 3.80353 14.1641 6.6264 14.1641 10.1179C14.1641 13.6093 16.9968 16.4322 20.5005 16.4322C24.0041 16.4322 26.8369 13.6093 26.8369 10.1179C26.8369 6.6264 24.0041 3.80353 20.5005 3.80353ZM20.5005 13.8322C18.4505 13.8322 16.7732 12.1607 16.7732 10.1179C16.7732 8.07499 18.4505 6.40355 20.5005 6.40355C22.5505 6.40355 24.2278 8.07499 24.2278 10.1179C24.2278 12.1607 22.5505 13.8322 20.5005 13.8322Z"
        fill="#006AF5"
      />
      <path
        d="M40.1239 3.72922C36.5829 3.72922 33.7129 6.58924 33.7129 10.1178C33.7129 13.6464 36.5829 16.5065 40.1239 16.5065C43.6648 16.5065 46.5348 13.6464 46.5348 10.1178C46.5535 6.58924 43.6648 3.72922 40.1239 3.72922ZM40.1239 13.8693C38.0366 13.8693 36.3593 12.1793 36.3593 10.1178C36.3593 8.05639 38.0552 6.34781 40.1239 6.34781C42.1925 6.34781 43.8884 8.03782 43.8884 10.0993C43.8884 12.1607 42.2112 13.8693 40.1239 13.8693Z"
        fill="#006AF5"
      />
    </svg>
  );
};

export const CloudIconComponent = (props) => {
  return (
    <svg
      {...props}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 45.1452C5 54.8718 12.8648 62.75 22.5748 62.75H59.7862C68.1994 62.75 75 55.9377 75 47.5103C75 39.4458 68.7727 32.8605 60.8641 32.308C59.9966 21.6495 51.097 13.25 40.1995 13.25C30.9373 13.25 23.1406 19.3224 20.4641 27.7145C11.7641 28.7559 5 36.1373 5 45.1452Z"
        stroke="#53575A"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PlusIconComponent = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2"
    >
      <path
        d="M7.99967 14.6667C4.31767 14.6667 1.33301 11.682 1.33301 8C1.33301 4.318 4.31767 1.33333 7.99967 1.33333C11.6817 1.33333 14.6663 4.318 14.6663 8C14.6663 11.682 11.6817 14.6667 7.99967 14.6667ZM7.33301 7.33333H4.66634V8.66666H7.33301V11.3333H8.66634V8.66666H11.333V7.33333H8.66634V4.66666H7.33301V7.33333Z"
        fill="#006AF5"
      />
    </svg>
  );
};

export const AddImgComponent = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20.0001" r="20" fill="#FAFAFA" />
      <g clipPath="url(#clip0_87_35607)">
        <path
          d="M27.5 22.5001V25.0001H30V26.6667H27.5V29.1667H25.8333V26.6667H23.3333V25.0001H25.8333V22.5001H27.5ZM27.5066 12.5001C27.9633 12.5001 28.3333 12.8709 28.3333 13.3276V21.1184C27.798 20.9293 27.2343 20.8329 26.6666 20.8334V14.1667H13.3333L13.3341 25.8334L21.0775 18.0892C21.2208 17.9455 21.4116 17.859 21.6142 17.8461C21.8168 17.8332 22.0171 17.8948 22.1775 18.0192L22.255 18.0901L25.21 21.0484C24.5638 21.2453 23.9643 21.571 23.4474 22.0059C22.9306 22.4407 22.5071 22.9758 22.2027 23.5787C21.8982 24.1817 21.719 24.84 21.6758 25.5141C21.6327 26.1882 21.7265 26.864 21.9516 27.5009L12.4933 27.5001C12.274 27.4998 12.0637 27.4126 11.9087 27.2574C11.7537 27.1022 11.6666 26.8919 11.6666 26.6726V13.3276C11.6682 13.1087 11.7557 12.8992 11.9104 12.7444C12.0651 12.5896 12.2744 12.5018 12.4933 12.5001H27.5066ZM16.6666 15.8334C17.1087 15.8334 17.5326 16.009 17.8451 16.3215C18.1577 16.6341 18.3333 17.058 18.3333 17.5001C18.3333 17.9421 18.1577 18.366 17.8451 18.6786C17.5326 18.9911 17.1087 19.1667 16.6666 19.1667C16.2246 19.1667 15.8007 18.9911 15.4881 18.6786C15.1756 18.366 15 17.9421 15 17.5001C15 17.058 15.1756 16.6341 15.4881 16.3215C15.8007 16.009 16.2246 15.8334 16.6666 15.8334Z"
          fill="#AEAEAE"
        />
      </g>
      <defs>
        <clipPath id="clip0_87_35607">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 10.0001)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const WarningIconComponent = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72975 2.28814C7.29427 1.31037 8.70557 1.31036 9.27009 2.28814L14.809 11.8818C15.3735 12.8595 14.6678 14.0818 13.5388 14.0818H2.46104C1.332 14.0818 0.626351 12.8595 1.19087 11.8818L6.72975 2.28814ZM7.37248 5.62631C7.36081 5.28394 7.63519 5.00004 7.97776 5.00004H8.02228C8.36485 5.00004 8.63924 5.28394 8.62757 5.62631L8.50094 9.34073C8.49299 9.57378 8.30177 9.75859 8.06859 9.75859H7.93145C7.69827 9.75859 7.50705 9.57378 7.49911 9.34073L7.37248 5.62631ZM8.70672 11.2907C8.70672 11.681 8.39032 11.9974 8.00001 11.9974C7.6097 11.9974 7.29329 11.681 7.29329 11.2907C7.29329 10.9004 7.6097 10.584 8.00001 10.584C8.39032 10.584 8.70672 10.9004 8.70672 11.2907Z"
        fill="#DC1F18"
      />
    </svg>
  );
};

export const EditIconComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      className=" mr-2 mb-1"
    >
      <path
        d="M2.276 9.66667L9.03733 2.90534L8.09467 1.96267L1.33333 8.72401V9.66667H2.276ZM2.82867 11H0V8.17134L7.62333 0.548005C7.74835 0.423024 7.91789 0.352814 8.09467 0.352814C8.27144 0.352814 8.44098 0.423024 8.566 0.548005L10.452 2.434C10.577 2.55902 10.6472 2.72856 10.6472 2.90534C10.6472 3.08211 10.577 3.25165 10.452 3.37667L2.82867 11ZM0 12.3333H12V13.6667H0V12.3333Z"
        fill="#006AF5"
      />
    </svg>
  );
};

export const RemoveIconComponent = (props: any) => {
  const { margin = true } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      className={margin ? " mr-2 mb-1" : ""}
    >
      <path
        d="M3.93962 1.37424C3.93962 0.745563 4.47374 0.267578 5.09295 0.267578H6.90628C7.51392 0.267578 8.05962 0.734294 8.05962 1.37424V1.93424H10.9996C11.2205 1.93424 11.3996 2.11333 11.3996 2.33424C11.3996 2.55516 11.2205 2.73424 10.9996 2.73424H0.999609C0.778695 2.73424 0.599609 2.55516 0.599609 2.33424C0.599609 2.11333 0.778695 1.93424 0.999609 1.93424H3.93962V1.37424ZM7.25962 1.37424V1.93424H4.73962V1.37424C4.73962 1.22959 4.87215 1.06758 5.09295 1.06758H6.90628C7.12531 1.06758 7.25962 1.22753 7.25962 1.37424ZM2.35864 4.30619C2.34314 4.08582 2.15193 3.91974 1.93156 3.93525C1.71119 3.95075 1.54511 4.14196 1.56061 4.36233L2.08635 11.8358C2.09433 12.892 2.95261 13.7343 4.00629 13.7343H7.94629C9.00108 13.7343 9.85821 12.8844 9.86624 11.8358L10.392 4.36233C10.4075 4.14196 10.2414 3.95075 10.021 3.93525C9.80066 3.91974 9.60945 4.08582 9.59395 4.30619L9.06728 11.7929C9.06662 11.8022 9.06629 11.8116 9.06629 11.8209C9.06629 12.4322 8.56653 12.9343 7.94629 12.9343H4.00629C3.38491 12.9343 2.88629 12.4377 2.88629 11.8209C2.88629 11.8116 2.88597 11.8022 2.88531 11.7929L2.35864 4.30619Z"
        fill="#006AF5"
        stroke="#006AF5"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StarIconComponent = (props) => {
  const { style = {}, ...another } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      style={{ marginRight: 16, ...style }}
      {...another}
    >
      <path
        d="M10.0002 15L4.12223 18.59L5.72023 11.89L0.490234 7.41L7.35523 6.86L10.0002 0.5L12.6452 6.86L19.5112 7.41L14.2802 11.89L15.8782 18.59L10.0002 15Z"
        fill="#FFB800"
      />
    </svg>
  );
};

export const ArrowDownComponent = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.1722L16.95 8.22217L18.364 9.63617L12 16.0002L5.63599 9.63617L7.04999 8.22217L12 13.1722Z"
        fill="black"
      />
    </svg>
  );
};

export const ArrowUpComponent = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10.828L7.04999 15.778L5.63599 14.364L12 8L18.364 14.364L16.95 15.778L12 10.828Z"
        fill="black"
      />
    </svg>
  );
};

export const DnDIconComponent = React.forwardRef((props: any, ref: any) => {
  return (
    // <svg
    //   cursor="move"
    //   width="6"
    //   height="18"
    //   viewBox="0 0 6 18"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path
    //     d="M0 0H2V2H0V0ZM4 0H6V2H4V0ZM0 4H2V6H0V4ZM4 4H6V6H4V4ZM0 8H2V10H0V8ZM4 8H6V10H4V8ZM0 12H2V14H0V12ZM4 12H6V14H4V12ZM0 16H2V18H0V16ZM4 16H6V18H4V16Z"
    //     fill="black"
    //   />
    // </svg>

    <svg
      cursor="move"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0625 6.005C11.0625 5.0375 10.2225 4.25 9.1875 4.25C8.1525 4.25 7.3125 5.0375 7.3125 6.005C7.3125 6.974 8.1525 7.76 9.1875 7.76C10.2225 7.76 11.0625 6.974 11.0625 6.005ZM11.0625 18.995C11.0625 18.026 10.2225 17.24 9.1875 17.24C8.1525 17.24 7.3125 18.026 7.3125 18.995C7.3125 19.9625 8.1525 20.75 9.1875 20.75C10.2225 20.75 11.0625 19.9625 11.0625 18.995ZM9.1875 10.745C10.2225 10.745 11.0625 11.5325 11.0625 12.5C11.0625 13.4675 10.2225 14.255 9.1875 14.255C8.1525 14.255 7.3125 13.4675 7.3125 12.5C7.3125 11.5325 8.1525 10.745 9.1875 10.745ZM16.6875 6.005C16.6875 5.0375 15.8475 4.25 14.8125 4.25C13.7775 4.25 12.9375 5.0375 12.9375 6.005C12.9375 6.974 13.7775 7.76 14.8125 7.76C15.8475 7.76 16.6875 6.974 16.6875 6.005ZM14.8125 17.24C15.8475 17.24 16.6875 18.026 16.6875 18.995C16.6875 19.9625 15.8475 20.75 14.8125 20.75C13.7775 20.75 12.9375 19.9625 12.9375 18.995C12.9375 18.026 13.7775 17.24 14.8125 17.24ZM16.6875 12.5C16.6875 11.5325 15.8475 10.745 14.8125 10.745C13.7775 10.745 12.9375 11.5325 12.9375 12.5C12.9375 13.4675 13.7775 14.255 14.8125 14.255C15.8475 14.255 16.6875 13.4675 16.6875 12.5Z"
        fill="#8C8C8C"
      />
    </svg>
  );
});

export const SearchIconComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0999 7.9999C2.0999 4.74142 4.74142 2.0999 7.9999 2.0999C11.2584 2.0999 13.8999 4.74142 13.8999 7.9999C13.8999 11.2584 11.2584 13.8999 7.9999 13.8999C4.74142 13.8999 2.0999 11.2584 2.0999 7.9999ZM7.9999 0.899902C4.07868 0.899902 0.899902 4.07868 0.899902 7.9999C0.899902 11.9211 4.07868 15.0999 7.9999 15.0999C9.67234 15.0999 11.2097 14.5216 12.423 13.5542C12.4267 13.558 12.4305 13.5618 12.4343 13.5656L17.4343 18.5656C17.7467 18.8781 18.2532 18.8781 18.5656 18.5656C18.8781 18.2532 18.8781 17.7467 18.5656 17.4343L13.5656 12.4343C13.5618 12.4305 13.558 12.4267 13.5542 12.423C14.5216 11.2097 15.0999 9.67234 15.0999 7.9999C15.0999 4.07868 11.9211 0.899902 7.9999 0.899902Z"
        fill="#141415"
      />
    </svg>
  );
};

export const ExcelIconComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="19"
      viewBox="0 0 20 24"
      fill="none"
      className=" mr-3 mb-1"
    >
      <path
        d="M19.1762 6.01607L13.4119 0.251786C13.2512 0.0910715 13.0342 0 12.8065 0H1.42794C0.953837 0 0.570801 0.383036 0.570801 0.857143V23.1429C0.570801 23.617 0.953837 24 1.42794 24H18.5708C19.0449 24 19.4279 23.617 19.4279 23.1429V6.62411C19.4279 6.39643 19.3369 6.17679 19.1762 6.01607ZM17.4512 7.01786H12.4101V1.97679L17.4512 7.01786ZM17.4994 22.0714H2.49937V1.92857H10.5887V7.71429C10.5887 8.01265 10.7072 8.2988 10.9182 8.50978C11.1291 8.72076 11.4153 8.83929 11.7137 8.83929H17.4994V22.0714ZM10.0556 13.8241L8.40027 11.0813C8.34134 10.9848 8.23687 10.9259 8.12437 10.9259H7.0958C7.03419 10.9259 6.97527 10.942 6.92437 10.9768C6.77437 11.0705 6.72884 11.2688 6.82527 11.4214L9.02973 14.9143L6.7958 18.4714C6.76545 18.5202 6.74868 18.5762 6.74724 18.6337C6.74579 18.6911 6.75972 18.7479 6.78757 18.7981C6.81543 18.8484 6.85621 18.8903 6.90569 18.9195C6.95517 18.9487 7.01156 18.9642 7.06902 18.9643H7.99312C8.10562 18.9643 8.20741 18.9054 8.26634 18.8116L9.9458 16.0929L11.6146 18.8089C11.6735 18.9054 11.7779 18.9616 11.8878 18.9616H12.8922C12.9538 18.9616 13.0128 18.9429 13.0663 18.9107C13.2163 18.8143 13.2592 18.6161 13.1628 18.4661L10.9128 14.9732L13.1976 11.4241C13.2285 11.3755 13.2458 11.3194 13.2478 11.2618C13.2497 11.2042 13.2361 11.1471 13.2085 11.0965C13.1809 11.0459 13.1402 11.0037 13.0907 10.9741C13.0412 10.9446 12.9847 10.9289 12.9271 10.9286H11.9708C11.8583 10.9286 11.7538 10.9875 11.6949 11.0839L10.0556 13.8241Z"
        fill="#0068FF"
      />
    </svg>
  );
};

export const LinkComponent = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_601_20503)">
        <path
          d="M17.6568 14.828L16.2428 13.414L17.6568 12C18.0282 11.6286 18.3229 11.1876 18.5239 10.7023C18.7249 10.217 18.8284 9.69681 18.8284 9.1715C18.8284 8.6462 18.7249 8.12605 18.5239 7.64073C18.3229 7.15542 18.0282 6.71445 17.6568 6.343C17.2853 5.97156 16.8444 5.67691 16.359 5.47589C15.8737 5.27487 15.3536 5.1714 14.8283 5.1714C14.303 5.1714 13.7828 5.27487 13.2975 5.47589C12.8122 5.67691 12.3712 5.97156 11.9998 6.343L10.5858 7.757L9.17177 6.343L10.5858 4.929C11.7141 3.81892 13.2353 3.19966 14.8181 3.2061C16.401 3.21255 17.9171 3.84418 19.0364 4.96341C20.1556 6.08265 20.7872 7.5988 20.7937 9.18162C20.8001 10.7644 20.1808 12.2857 19.0708 13.414L17.6568 14.828ZM14.8278 17.657L13.4138 19.071C12.8583 19.6356 12.1965 20.0846 11.4666 20.3922C10.7367 20.6998 9.95319 20.8598 9.16115 20.863C8.36911 20.8662 7.58428 20.7126 6.85191 20.411C6.11954 20.1094 5.45414 19.6658 4.89408 19.1057C4.33402 18.5456 3.89039 17.8802 3.58878 17.1479C3.28717 16.4155 3.13354 15.6307 3.13677 14.8386C3.13999 14.0466 3.3 13.263 3.60757 12.5331C3.91513 11.8033 4.36417 11.1415 4.92877 10.586L6.34277 9.172L7.75677 10.586L6.34277 12C5.97133 12.3714 5.67668 12.8124 5.47566 13.2977C5.27463 13.783 5.17117 14.3032 5.17117 14.8285C5.17117 15.3538 5.27463 15.874 5.47566 16.3593C5.67668 16.8446 5.97133 17.2856 6.34277 17.657C6.71421 18.0284 7.15518 18.3231 7.6405 18.5241C8.12581 18.7251 8.64597 18.8286 9.17127 18.8286C9.69657 18.8286 10.2167 18.7251 10.702 18.5241C11.1874 18.3231 11.6283 18.0284 11.9998 17.657L13.4138 16.243L14.8278 17.657ZM14.8278 7.757L16.2428 9.172L9.17177 16.242L7.75677 14.828L14.8278 7.757Z"
          fill="#0068FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_601_20503">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AvatarIconComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10.6402 9.36797C10.3876 8.76954 10.021 8.22596 9.56078 7.76752C9.10198 7.30776 8.55849 6.94119 7.96033 6.68806C7.95497 6.68538 7.94962 6.68404 7.94426 6.68136C8.77863 6.07868 9.32104 5.09699 9.32104 3.9894C9.32104 2.15458 7.83444 0.667969 5.99962 0.667969C4.16479 0.667969 2.67819 2.15458 2.67819 3.9894C2.67819 5.09699 3.2206 6.07868 4.05497 6.6827C4.04961 6.68538 4.04426 6.68672 4.0389 6.6894C3.4389 6.94252 2.90051 7.30547 2.43845 7.76886C1.97869 8.22766 1.61212 8.77114 1.35899 9.36931C1.11031 9.95492 0.976198 10.5828 0.963901 11.2189C0.963543 11.2332 0.96605 11.2474 0.971275 11.2607C0.976499 11.274 0.984334 11.2861 0.994319 11.2964C1.0043 11.3066 1.01624 11.3147 1.02941 11.3203C1.04259 11.3258 1.05675 11.3287 1.07104 11.3287H1.87462C1.93354 11.3287 1.98042 11.2818 1.98176 11.2242C2.00854 10.1903 2.42372 9.22199 3.15765 8.48806C3.91703 7.72868 4.92551 7.31083 5.99962 7.31083C7.07372 7.31083 8.0822 7.72868 8.84158 8.48806C9.57551 9.22199 9.99069 10.1903 10.0175 11.2242C10.0188 11.2831 10.0657 11.3287 10.1246 11.3287H10.9282C10.9425 11.3287 10.9566 11.3258 10.9698 11.3203C10.983 11.3147 10.9949 11.3066 11.0049 11.2964C11.0149 11.2861 11.0227 11.274 11.028 11.2607C11.0332 11.2474 11.0357 11.2332 11.0353 11.2189C11.0219 10.5787 10.8893 9.95592 10.6402 9.36797ZM5.99962 6.29297C5.38488 6.29297 4.80631 6.05324 4.37104 5.61797C3.93578 5.1827 3.69604 4.60413 3.69604 3.9894C3.69604 3.37467 3.93578 2.79609 4.37104 2.36083C4.80631 1.92556 5.38488 1.68583 5.99962 1.68583C6.61435 1.68583 7.19292 1.92556 7.62819 2.36083C8.06345 2.79609 8.30319 3.37467 8.30319 3.9894C8.30319 4.60413 8.06345 5.1827 7.62819 5.61797C7.19292 6.05324 6.61435 6.29297 5.99962 6.29297Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export const LockIconComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="100"
      viewBox="0 0 79 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.0005 39H20.5005V26.5C20.5005 16.5 29.0005 8 39.5005 8C50.0005 8 58.5005 16.5 58.5005 26.5V39H58.0005ZM43.0005 71V83.5H35.0005V71C32.5005 69.5 31.0005 67 31.0005 64.5C31.0005 60.5 34.5005 57 39.0005 57C43.5005 57 47.0005 60.5 47.0005 64.5C47.0005 67 45.5005 69.5 43.0005 71ZM70.5005 39H66.0005V26.5C66.0005 12.5 54.0005 0.5 39.0005 0.5C24.0005 0.5 12.5005 12 12.5005 26.5V39H8.00049C3.50049 39 0.000488281 42.5 0.000488281 46.5V92.5C0.000488281 96.5 3.50049 100 8.00049 100H70.5005C75.0005 100 78.5005 96.5 78.5005 92.5V46.5C78.5005 42.5 75.0005 39 70.5005 39Z"
        fill="#767A7F"
      />
    </svg>
  );
};
