/* eslint-disable no-plusplus */
export const dataToUri = (data) => {
  try {
    if (!data) {
      return '';
    }
    return btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  } catch (e) {
    return undefined;
  }
};

export const uriToData = (str) => {
  try {
    if (!str || str.length === 0) {
      return {};
    }
    return JSON.parse(decodeURIComponent(escape(atob(str))));
  } catch (e) {
    return undefined;
  }
};

export const removeDotFormat = (text) => `${text}`.replace(/[.]/g, '');

export const formatMoney = (amount, char = '.') => {
  if (amount === undefined || amount === null) {
    return '';
  }

  return `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, char);
};

const tokenFormatDate = /D{1,2}|M{1,2}|YY(?:YY)?|([Hms])\1?|"[^"]*"|'[^']*'/g;

function pad(vNumber, width = 2) {
  let numAsString = vNumber.toString();
  while (numAsString.length < width) {
    numAsString = '0' + numAsString;
  }
  return numAsString;
}

export function formatDate(date, mask = 'HH:mm DD-MM-YYYY') {
  if (!(date instanceof Date)) {
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }

  if (Number.isNaN(date)) {
    throw TypeError('Invalid date');
  }

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const flags = {
    H: hours,
    HH: pad(hours),
    m: minutes,
    mm: pad(minutes),
    s: seconds,
    ss: pad(seconds),
    D: day,
    DD: pad(day),
    M: month + 1,
    MM: pad(month + 1),
    YY: String(year).slice(2),
    YYYY: year,
  };

  return mask.replace(tokenFormatDate, function replacer(match) {
    if (match in flags) {
      return flags[match];
    }
    return match.slice(1, match.length - 1);
  });
}

export const milToDate = (mil) =>{
  const date = new Date(mil)

  const toDate =
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear();

  return toDate;
}

export const milToTime = (mil) => {
  const date = new Date(mil)

  const toTime =
      [date.getHours() > 10 ?  date.getHours() : "0" + date.getHours()] +
      ":" +
      [date.getMinutes() > 10 ? date.getMinutes() : "0" + date.getMinutes()];

  return toTime;
};

export const convertUnsignedString = (input: string) => {
  const signedChars =
    'àảãáạăằẳẵắặâầẩẫấậđèẻẽéẹêềểễếệìỉĩíịòỏõóọôồổỗốộơờởỡớợùủũúụưừửữứựỳỷỹýỵÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬĐÈẺẼÉẸÊỀỂỄẾỆÌỈĨÍỊÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢÙỦŨÚỤƯỪỬỮỨỰỲỶỸÝỴ';
  const unsignedChars =
    'aaaaaaaaaaaaaaaaadeeeeeeeeeeeiiiiiooooooooooooooooouuuuuuuuuuuyyyyyAAAAAAAAAAAAAAAAADEEEEEEEEEEEIIIIIOOOOOOOOOOOOOOOOOUUUUUUUUUUUYYYYY';
  const pattern = new RegExp(`[${signedChars}]`, 'g');
  const output = input.replace(pattern, (m: any) => unsignedChars.charAt(signedChars.indexOf(m)));
  return output;
};