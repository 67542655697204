import { CustomBtn } from "components";
import { RemoveIconComponent } from "components/Images";
import { Selector } from "components/Input";

export const ROLE_LABELS = {
  menu: "Thực đơn",
  booking: "Thông tin đặt bàn",
  feedback: "Quản lý góp ý - Phản hồi",
  customerInfo: "Thông tin khách hàng",
};

export const PERMISSIONS = {
  VIEW_ONLY: "accessible",
  NOT_ACCESS: "inaccessible",
  EDITABLE: "modifiable",
};

export const STATUS_LABEL = {
  view_only: "Chỉ xem",
  editable: "Có thể chỉnh sửa",
  not_access: "Không được truy cập",
};

export const ACCESS_STATUS = [
  {
    value: PERMISSIONS.VIEW_ONLY,
    label: STATUS_LABEL.view_only,
  },
  {
    value: PERMISSIONS.NOT_ACCESS,
    label: STATUS_LABEL.not_access,
  },
  {
    value: PERMISSIONS.EDITABLE,
    label: STATUS_LABEL.editable,
  },
];

export const ACCESS_ROLES = {
  MENU: "menu",
  BOOKING: "booking",
  FEEDBACK: "feedback",
  CUSTOMER_INFO: "customer",
};


export const AccountTableColumns = [
  {
    key: "logo_url",
    dataIndex: "logo_url",
    width: 64,
    render: (imageURL) => <img src={imageURL} width="40" height="40" />,
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Tên nhà hàng",
  },
  {
    key: "address",
    dataIndex: "address",
    title: "Địa chỉ",
  },
];


export const UserDetailColumns = ({
  onChangeMenuAccess,
  onChangeBookingAccess,
  onChangeCustomerInfoAccess,
  onChangeFeedbackAccess,
  onRemove,
}: {
  onChangeMenuAccess: (...params: any) => any;
  onChangeBookingAccess: (...params: any) => any;
  onChangeFeedbackAccess: (...params: any) => any;
  onChangeCustomerInfoAccess: (...params: any) => any;
  onRemove: (param: any) => any;
}) => [
  {
    key: "logo_url",
    dataIndex: "logo_url",
    width: 64,
    render: (imageURL) => <img src={imageURL} width="40" height="40" />,
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Tên nhà hàng",
  },
  {
    key: "address",
    dataIndex: "address",
    title: "Địa chỉ",
  },
  {
    key: "",
    dataIndex: "",
    title: "Truy cập thực đơn",
    render: (_: any, record: any) => {
      const permission = record.permissions;
      return (
        <Selector
          value={permission?.menu}
          handleChange={(menu) => onChangeMenuAccess(record, menu)}
        />
      );
    },
  },
  {
    key: "",
    dataIndex: "",
    title: "Truy cập thông tin đặt bàn",
    render: (_: any, record: any) => {
      const permission = record.permissions;
      return (
        <Selector
          value={permission?.booking}
          handleChange={(booking) => onChangeBookingAccess(record, booking)}
        />
      );
    },
  },
  {
    key: "",
    dataIndex: "",
    title: "Truy cập quản lý góp ý - Phản hồi",
    render: (_: any, record: any) => {
      const permission = record.permissions;
      return (
        <Selector
          value={permission?.feedback}
          handleChange={(feedback) => onChangeFeedbackAccess(record, feedback)}
        />
      );
    },
  },
  {
    key: "",
    dataIndex: "",
    title: "Truy cập thông tin khách hàng",
    render: (_: any, record: any) => {
      const permission = record.permissions;

      return (
        <Selector
          value={permission?.customer}
          handleChange={(customer) =>
            onChangeCustomerInfoAccess(record, customer)
          }
        />
      );
    },
  },
  {
    key: "operation",
    dataIndex: "operation",
    title: "",
    width: 76,
    height: 68,
    render: (_: any, record: any) => {
      return (
        <div style={{ width: 52, height: 34 }}>
          <CustomBtn
            nonText={true}
            style={{ height: 34 }}
            type="primary"
            onClick={() => onRemove(record)}
          >
            <RemoveIconComponent margin={false} />
          </CustomBtn>
        </div>
      );
    },
  },
];
