export const LOCAL_STORAGE_KEY = {
    SELECTED_RESTAURANT: 'selectedRestaurant',
    SELECTED_USER: 'selectedUser',
    AUTH: 'auth',
    USER: 'user',
  };
  
  export const ZMP_PREFIX_LINK = 'https://zalo.me/s';
  
  export const WEEK_DAYS = {
    0: {
      keyName: "S",
      displayName: "Chủ Nhật",
    },
    1: {
      keyName: "M",
      displayName: "Hai",
    },
    2: {
      keyName: "T",
      displayName: "Ba",
    },
    3: {
      keyName: "W",
      displayName: "Tư",
    },
    4: {
      keyName: "T",
      displayName: "Năm",
    },
    5: {
      keyName: "F",
      displayName: "Sáu",
    },
    6: {
      keyName: "S",
      displayName: "Bảy",
    },
  };
  
  export const INPUT_MAX_LENGTH = 20;
  export const PHONE_MAX_LENGTH = 12;
  export const PHONE_MIN_LENGTH = 9;
  export const VIETNAMESE_PHONE_REGEX_LIST = [
    /^(3[2-9])(\d+)/,
    /^(5[2|5|6|8|9])(\d+)/,
    /^(7[0|6|7|8|9])(\d+)/,
    /^(8[1-9])(\d+)/,
    /^(9[0-9])(\d+)/,
  ];
  
  export const VIETNAMESE_AREA_CODE_PHONE_REGEX = /^(840|84|0)(\d+)/;
  export const VIETNAMESE_AREA_CODE_PHONE_PREFIX = /^(840|84|0)/;
  
  export const ERROR_MESSAGE = {
    blankPhoneNumber: "Bạn chưa nhập số điện thoại",
    invalidPhoneNumber: "Số điện thoại không đúng định dạng",
    invalidPrefixPhoneNumber: "Định dạng số điện thoại đúng bắt đầu với 0/84/840",
    invalidLengthPhoneNumber: {
      lessThan: "Vui lòng nhập đủ số điện thoại",
      moreThan: "Bạn nhập quá số kí tự cho phép",
    },
  };
  
  export const FORM_ACTION = {
    CREATE: "create",
    EDIT: "edit",
  };

  export const WEB_PAGE = {
    MENU: "menu",
    BOOKING: "booking",
    FEEDBACK: "feedback",
    CUSTOMER: "customer",
    ACCESS: "access",
    USER: "user",
  };
  
  export const ERROR_CODES = {
    SUCCESS: 1,
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    NOT_ACCEPT: 406,
  };
  
  export const SUCCESS_ERROR_CODES = [
    ERROR_CODES.SUCCESS,
    ERROR_CODES.OK,
    ERROR_CODES.CREATED,
    ERROR_CODES.NO_CONTENT,
  ];
  
  export const IMG_UPLOAD_INPUT_ID = "UPLOAD_IMG_INPUT";
  export const TEXT_AREA_MAX_LENGTH = 1000;
  