import { useEffect } from 'react';
import { message } from 'antd';
import { initEvents, EVENTS } from 'utils/eventEmitter';
import { IShowNotify } from 'types';

const useNotify = () => {
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const destroyEvent = initEvents([{ eventName: EVENTS.SHOW_NOTIFY, listener: (options: IShowNotify) => {
      const { type, content = '', ...another } = options;
      if (type === 'destroy') {
        messageApi.destroy();
        return;
      }
      if (type === 'loading') {
        messageApi.open({ type, content, ...another });
        return
      }
      messageApi.open({ duration: 2, type, content, ...another });
    }
  }]);
  return destroyEvent;
  }, []);

  return { contextHolder };
};

export default useNotify;