import React, { useState } from "react";
import dayjs from "dayjs";
import { DatePicker as AntdDatePicker, Space, Upload, Input, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { AddImgComponent, WarningIconComponent, StarIconComponent } from "./Images";
import styles from "./styles.module.scss";
import { CustomBtn } from "components";
import { getBase64 } from "utils/image";
import { IMG_UPLOAD_INPUT_ID, TEXT_AREA_MAX_LENGTH } from "../constants/index";
import { ACCESS_STATUS } from "constants/Setting";

const { RangePicker: RangePickerLib, TimePicker: TimePickerLib } =
  AntdDatePicker;

const disabledDate = (current) => {
  return current < dayjs().startOf("day");
};

export const RangePicker = (props: any) => {
  return <RangePickerLib disabledDate={disabledDate} {...props} />;
};

export const DatePicker = (props: any) => {
  return <AntdDatePicker disabledDate={disabledDate} {...props} />;
};

export const TimePicker = (props: any) => {
  return <TimePickerLib {...props} />;
};

export const InputWrapper = ({
  children,
  required,
  title,
  classnames
}: {
  children: React.ReactNode;
  required?: boolean;
  title?: string;
  classnames ?: string;
}) => {
  if (!children) return null;
  return (
    <div className={classnames}>
      <Title required={required}>{title}</Title>
      {children}
    </div>
  );
};

const Title = ({
  children,
  required,
}: {
  children: React.ReactNode;
  required?: boolean;
}) => {
  if (!children) return null;
  return (
    <div style={{ marginBottom: 2 }}>
      {children}
      {required && <span style={{ color: "#DC1F18", marginLeft: 2 }}>*</span>}
    </div>
  );
};

const acceptedFileType = ["image/jpeg", "image/png", "image/jpg"];
const dummyRequest = ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
export const ImgUpload = ({
  onChange,
  imgURL,
  setImgURL,
}: {
  onChange: (img?: File) => any;
  imgURL?: string;
  setImgURL: (params: any) => any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const beforeUpload = (file: RcFile) => {
    const isAcceptedFileType = acceptedFileType.includes(file.type);
    if (!isAcceptedFileType) {
      setErrMsg("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setErrMsg("Image must smaller than 2MB!");
    }
    return isAcceptedFileType && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      onChange(info.file.originFileObj as File);
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImgURL(url);
      });
    }
  };

  const handleRemoveImg = () => {
    onChange();
    setImgURL("");
  };

  const uploadButton = (
    <div>{loading ? <LoadingOutlined /> : <AddImgComponent />}</div>
  );

  return (
    <>
      <div className={styles["image-upload-wrapper"]}>
        <Upload
          name="upload-image"
          listType="picture-card"
          id={IMG_UPLOAD_INPUT_ID}
          className={styles["image-upload"]}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          customRequest={dummyRequest}
          accept="image/jpeg,image/jpg,image/png"
        >
          {imgURL ? (
            <img
              src={imgURL}
              alt="avatar"
              style={{ width: 100, height: 100, borderRadius: 8 }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
        <Space size={16} direction="vertical" className={styles["info-block"]}>
          {imgURL && (
            <Space size={24} style={{ alignItems: 'center' }}>
              <CustomBtn
                onClick={() =>
                  document.getElementById(IMG_UPLOAD_INPUT_ID)?.click()
                }
              >
                Tải ảnh mới
              </CustomBtn>
              <p onClick={handleRemoveImg} className={styles["remove-btn"]}>
                Xoá ảnh
              </p>
            </Space>
          )}
          <p>
            Chấp nhận file ảnh có định dạng .JPG hoặc .PNG và có dung lượng dưới
            2MB
          </p>
        </Space>
      </div>
      {errMsg && (
        <span className={styles["err-msg"]}>
          <WarningIconComponent />
          <p>{errMsg}</p>
        </span>
      )}
    </>
  );
};

type TextInputPropsType = {
  title?: string;
  required?: boolean;
  value?: string;
  onChange?: (value: any) => any;
  [key: string]: any;
};

export const TextAreaInput = (props: TextInputPropsType) => {
  const {
    title,
    value = "",
    maxLength = TEXT_AREA_MAX_LENGTH,
    ...anotherProps
  } = props;
  return (
    <Input.TextArea
      {...anotherProps}
      value={value}
      maxLength={maxLength}
      showCount
    />
  );
};

export const Selector = ({value, handleChange}: {value:any, handleChange: any}) => {
  return (
      <Select
          value={value}
          className="w-fit"
          style={{ width: 150 }}
          onChange={handleChange}
          options={ACCESS_STATUS}
      />
  );
};