import { getAccessToken } from 'utils/auth';
import { SUCCESS_ERROR_CODES } from '../constants/index';
import { clearStorage } from './helpers';

export function createRequest(opts: any) {
  const { baseURL } = opts;
  const mRequest = (url, options = {}) => {
    const extendOptions = {
      ...options,
      credentials: 'include',
    };
    if (url.startsWith('http')) {
      return wrapRequest(url, extendOptions);
    }
    const endpoint = `${baseURL}${url}`;
    return wrapRequest(endpoint, extendOptions);
  };
  return mRequest;
};

const wrapRequest = (url: string, options: any = {}) => {
  const { errorCallback, ...rawOptions } = options;
  return fetch(url, rawOptions)
    .then(checkAuthen)
    .then(parseApiResponse)
    .catch((e) => catchRequest(e, errorCallback));
};

const parseApiResponse = (data: any) => {
  const { code } = data || {};
  data.ok = !code || SUCCESS_ERROR_CODES.includes(code);
  return data;
};

const checkAuthen = async(response: any) => {
  const json = await response.json();
  if (response.status === 401 || json.code === 401) {
    clearStorage();
    window.location.replace(`/login?cb_url=${encodeURIComponent(window.location.href)}`);
    return null;
  }
  return json;
};

function catchRequest(e, errorCallback) {
  if (e === 'TypeError: Failed to fetch') {
    console.log(e);
  }
  return { ok: false };
}

const request = createRequest({ baseURL: 'https://backendmenu.zaminiapp.com' });

export default request;


export const get = (path: string, data?: any, options?: any) => {
  const { auth = true, headers, ...requestOptions } = options || {};
  const { formData } = data || {};
  const accessToken = getAccessToken();
  return request(path, {
    ...requestOptions,
    method: 'GET',
    body: formData ? formData : JSON.stringify(data),
    headers:!formData ? {
      'Content-Type': 'application/json',
      'Authorization': accessToken,
      ...headers,
    }:{
      'Authorization': accessToken,
        ...headers,
      }
  });
};

export const post = (path: string, data?: any, options?: any) => {
  const { auth = true, headers, ...requestOptions } = options || {};
  const { formData } = data;
  const accessToken = getAccessToken();
  return request(path, {
    ...requestOptions,
    method: 'POST',
    body: formData ? formData : JSON.stringify(data),
    headers: !formData ? {
      'Content-Type': 'application/json',
      'Authorization': accessToken,
      ...headers
    } : {
      'Authorization': accessToken,
        ...headers,
      }
  });
};


export const put = (path: string, data?: any, options?: any) => {
  const { auth = true, headers, ...requestOptions } = options || {};
  const { formData } = data;
  const accessToken = getAccessToken();
  return request(path, {
    ...requestOptions,
    method: 'PUT',
    body: formData ? formData : JSON.stringify(data),
    headers: !formData ? {
      'Content-Type': 'application/json',
      'Authorization': accessToken,
      ...headers
    } : {
      'Authorization': accessToken,
        ...headers,
      }
  });
};

export const remove = (path: string, data?: object | string, options?: any) => {
  const { auth = true, headers, ...requestOptions } = options || {};
  const accessToken = getAccessToken();
  return request(path, {
    ...requestOptions,
    method: 'DELETE',
    body: typeof data === 'object' ? JSON.stringify(data) : data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accessToken,
      ...headers
    }  });
};