import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { LOCAL_STORAGE_KEY } from '../constants/index';

const KEY = LOCAL_STORAGE_KEY.AUTH

export type TUser = {
  accessToken: string;
  refreshToken: string;
};

export const useAuth = () => useContext(AuthContext);

export const getTokenData = () => {
  if (typeof Storage === 'undefined') return {};
  return JSON.parse(localStorage.getItem(KEY) || '{}');
};

export const setTokenData = (user: Partial<TUser>) => {
  if (user?.constructor.name !== 'Object') {
    throw new Error('No valid data found');
  }
  if (Object.keys(user).length === 0) {
    throw new Error('No data found');
  }
  if (typeof Storage === 'undefined') {
    throw new Error('No valid storage type found');
  }
  localStorage.setItem(KEY, JSON.stringify(user));
};

export function clearTokenData(): void {
  if (typeof Storage === 'undefined') return;
  localStorage.removeItem(KEY);
}

export const getRefreshToken = () => {
  if (typeof Storage === 'undefined') return false;
  return JSON.parse(localStorage.getItem(KEY) || '{}')?.refreshToken;
};

export const getAccessToken = () => {
  if (typeof Storage === 'undefined') {
    return new Error('Storage type not valid');
  }
  return JSON.parse(localStorage.getItem(KEY) || '{}')?.accessToken;
};

export const updateAccessToken = (token: string): void => {
  if (typeof Storage === 'undefined') return;
  const user = JSON.parse(localStorage.getItem(KEY) || '{}');
  user.accessToken = token;
  localStorage.setItem(KEY, JSON.stringify(user));
};

export const updateRefreshToken = (token: string): void => {
  if (typeof Storage === 'undefined') return;
  const user = JSON.parse(localStorage.getItem(KEY) || '{}');
  user.refreshToken = token;
  localStorage.setItem(KEY, JSON.stringify(user));
};

export const isAuthenticated = () => {
  const accessToken = getAccessToken();
  if (!accessToken) return false;
  return true;
};

export function getPayloadFromToken(token: string) {
  if (!token) { return {}; }
  const base64Url = token.split('.')[1] as string;
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}
