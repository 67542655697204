/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
const LOCAL = 'LOCAL';
const FLASH = 'FLASH';

function createStorage(type: string) {
  const storage = type === LOCAL ? window.localStorage : window.sessionStorage;
  const setString = function(key: string, value: string | undefined) {
    if (value === undefined) {
      storage.removeItem(key);
      return value;
    }
    storage.setItem(key, value);
    return value;
  };

  const setObject = function(this: {
      setString: (key: any, value: any) => any;
      /* eslint-disable no-underscore-dangle */
      /* eslint-disable func-names */
      getObject: (key: any) => any; getString: (key: any) => string | null; setObject /* eslint-disable func-names */: (key: any, object: any) => any;
    }, key, object) {
    if (!object) {
      storage.removeItem(key);
      return null;
    }
    this.setString(key, JSON.stringify(object));
    return object;
  };

  const getString = function(key: string) {
    return storage.getItem(key);
  };

  const getObject = function(this: { setString: (key: any, value: any) => any; getObject: (key: any) => any; getString: (key: any) => string | null; setObject: (key: any, object: any) => any; }, key) {
    const value = this.getString(key);
    if (value) {
      return JSON.parse(value);
    }
    return value;
  };
  
  const removeObject = function(key: string) {
    storage.removeItem(key);
  };

  return {
    setString,
    getObject,
    getString,
    setObject,
    removeObject
  };
}

export const local = createStorage(LOCAL);
export const flash = createStorage(FLASH);
export default {
  local,
  flash,
};
