import React from 'react';
import clsNames from 'classnames';
import styles from './styles.module.scss';

import { Button } from 'antd';

export const Header = ({ children, center = false }: { children: React.ReactNode, center?: boolean }) => {
  return (
    <div className={clsNames(center ? 'justify-center' : 'justify-between', 'flex bg-white rounded-lg p-6 mb-6 h-auto max-h-28	')}>
      {children}
    </div>
  )
};

export const Title = ({ children, style = {}, onClick }: { children: React.ReactNode, style?: object, onClick?: (...params: any) => any }) => {
  return (
    <p onClick={onClick} style={style} className="font-bold text-xl mb-0">
      {children}
    </p>
  )
};

export const SubTitle = ({ children, style = {}, onClick }: { children: React.ReactNode, style?: object, onClick?: (...params: any) => any }) => {
  return (
    <p onClick={onClick} style={style} className="font-bold text-lg mb-0">
      {children}
    </p>
  )
};

export const CustomBtn = (props: any) => {
  const { children, style, backgroundNone = false, disabled, nonText, ...another } = props;
  return (
    <Button { ...another} disabled={disabled} style={style} className={clsNames(styles['custom-btn'], backgroundNone && styles['bg-none'], disabled && styles['disabled'], nonText && styles['non-text'])}>
      {children}
    </Button>
  )
};

export const Section = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="bg-white rounded-lg w-full h-full">
      {children}
    </div>
  )
};

export const BookingStatusSelect = ({ children, status }: { children: React.ReactNode, status: string }) => {
  return <span className={clsNames(styles['booking-status'], styles[status])}>{children}</span>
};
