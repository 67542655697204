import type { RcFile } from 'antd/es/upload/interface';

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const getImageFromURL = async (url: string) => {
  try {
    const response = await fetch(url, { credentials: 'include' });
    const blob = await response.blob();
    const type = response.headers.get('Content-Type') || blob.type;
    return new File([blob], 'image.jpg', { type });
  } catch (e) {
    console.log('Get image error');
    return null;
  }
};