export const ROUTES = {
  LOGIN: "/login",
  MENU: {
    LIST: "/menu/list",
  },
  BOOKINGS: {
    LIST: "/booking/list",
    DETAIL: "/booking/:id",
  },

  CATEGORIES: {
    LIST: "/categories/list",
  },

  FEEDBACKS: {
    LIST: "/feedbacks/list",
  },

  CLIENTS: {
    LIST: "/clients/list",
  },
  RESTAURANTS: {
    LIST: "/restaurants",
  },
  SETTINGS: {
    ACCOUNT: "account",
    ACCESS: "access",
    USER: "access/user",
  },
  INTERFACE: {
    CONTROL: "/interface/control",
  },
  HISTORY: { 
    LIST: "/history/list" 
  },
  NOT_PERMISSION: "/not-permission",
};
