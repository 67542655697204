import { LOCAL_STORAGE_KEY } from "../constants/index";
import { convertUnsignedString } from "./formatter";
import { local } from 'utils/storage';
import { getImageFromURL } from "./image";


const tokenFormatDate = /D{1,2}|M{1,2}|YY(?:YY)?|([Hms])\1?|"[^"]*"|'[^']*'/g;
function pad(vNumber, width = 2) {
  let numAsString = vNumber.toString();
  while (numAsString.length < width) {
    numAsString = "0" + numAsString;
  }
  return numAsString;
}

export function formatDate(date, mask = "HH:mm DD-MM-YYYY") {
  if (!(date instanceof Date)) {
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }

  if (Number.isNaN(date)) {
    throw TypeError("Invalid date");
  }

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const flags = {
    H: hours,
    HH: pad(hours),
    m: minutes,
    mm: pad(minutes),
    s: seconds,
    ss: pad(seconds),
    D: day,
    DD: pad(day),
    M: month + 1,
    MM: pad(month + 1),
    YY: String(year).slice(2),
    YYYY: year,
  };

  return mask.replace(tokenFormatDate, function replacer(match) {
    if (match in flags) {
      return flags[match];
    }
    return match.slice(1, match.length - 1);
  });
}

export const objectToQueryString = (object: any) => {
  const obj = { ...object };
  Object.keys(obj).forEach((key) => {
    if (!obj[key] && obj[key] !== false) delete obj[key];
  });
  return Object.keys(obj)
    .map((key) => {
      if (Array.isArray(obj[key])) {
        let str = "";
        obj[key].forEach((value, idx, array) => {
          if (idx === array.length - 1) {
            str += `${key}[]=${value}`;
          } else {
            str += `${key}[]=${value}` + "&";
          }
        });
        return str;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join("&");
};

export const parseQueryString = (queryString: string = ""): any => {
  if (!queryString) return {};
  const query = queryString.substring(1);
  const vars = query.split("&");
  const data = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].replace(/=/, "&").split("&");
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1] || "");
    if (key.length > 0) {
      data[key] = value;
    }
  }
  return data;
};

export const arrToObj = (arr: any[]) => {
  const obj = {};
  arr.map((el) => (obj[el] = true));
  return obj;
};

export const shallowCompareObj = (obj1: any[], obj2: any[]) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const sleep = (ms: number = 1e3) =>
  new Promise((r) => setTimeout(r, ms));

export const createIncArray = (len: number) => {
  const arr: number[] = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

export const updateArray = (array: any[], data: any) => {
  return array.map((element) => {
    if (element.id !== data.id) return element;
    return { ...data };
  });
};

export const removeArray = (array: any[], id: any) => {
  return array.filter((element) => (element.id !== id));
};

export const compareString = (text?: string, keyword?: string) => {
  if (!keyword || keyword.trim() === '' || !text || text.trim() === '') {
    return false;
  }
  const unsignedText = convertUnsignedString(text.toLowerCase());
  const formattedKeyword = convertUnsignedString(keyword)
    .toLowerCase()
    .trim();
  return unsignedText.indexOf(formattedKeyword) !== -1;
};

export const clearStorage = () => {
  Object.values(LOCAL_STORAGE_KEY).map((value) => local.removeObject(value)) }

export const isURL = (text: string) => {
  const pattern = new RegExp('(https)|(http)|(wwww)');
  return pattern.test(text);
}

export const imagesToFiles = async(listImageObject: any) => {
  console.log('listImageObject: ', listImageObject);
  const listFileObject = await Promise.all(Object.keys(listImageObject).map(async(key) => {
    if(isURL(listImageObject[key])){
      const imgFile = await getImageFromURL(listImageObject[key]);
      return {[key]: imgFile};
    }
    return {[key]:listImageObject[key]}
  }))
  return Object.assign({}, ...listFileObject);
}