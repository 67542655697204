import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ConfirmPopup from "components/ConfirmPopup";
import useNotify from "hooks/useNotify";
import "./scss/style.scss";
import AuthProvider from "routes/AuthProvider";
import CategoryForm from "views/Category/component/Form";
import { ROUTES } from "constants/routes";
import { RecoilRoot } from "recoil";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/default/DefaultLayout"));
const SettingsLayout = React.lazy(() => import("./layout/settings/SettingsLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login"));
const Restaurants = React.lazy(() => import("./views/Restaurant/List"));

const App = () => {
  const { contextHolder } = useNotify();
  return (
    <RecoilRoot>
      <BrowserRouter>
        <CategoryForm />
        <Suspense fallback={loading}>
          {contextHolder}
          <ConfirmPopup />
          <AuthProvider>
            <Routes>
              <Route path={ROUTES.LOGIN} name="Login Page" element={<Login />} />
              <Route
                path={ROUTES.RESTAURANTS.LIST}
                name="Restaurant Page"
                element={<Restaurants />}
              />
              <Route path="*" name="Home" element={<DefaultLayout />} />
              <Route path={"/settings/*"} name="Settings" element={<SettingsLayout />} />
            </Routes>
          </AuthProvider>
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default App;
