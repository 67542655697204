import React, { useState, useEffect, useRef } from "react";
import { Input, Modal, Space } from "antd";
import { InputWrapper, ImgUpload } from "components/Input";
import { EVENTS, initEvents } from 'utils/eventEmitter';
import { IOpenCategoryForm } from 'types';

const CategoryForm = () => {
  const originalDataRef = useRef<any>({});
  const [name, setName] = useState<string>('');
  const [data, setData] = useState<any>({});
  // const [imgURL, setImgURL] = useState<string>('');
  // const [imgFile, setImgFile] = useState<any>(null);
  const { title = '', btnTxt = '', categoryID } = data;
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const callbackRef = useRef<any>({
    onSubmit: () => {},
    onClose: () => {},
  });
  // const imgChanged = imgURL !== originalDataRef.current.image_url;

  const emptyData = !name;
  const dataNotChange = name === originalDataRef.current.name;
  const btnDisabled = emptyData || dataNotChange;

  useEffect(() => {
    const destroyEvents = initEvents([{ eventName: EVENTS.OPEN_CATEGORY_FORM, listener: (options: IOpenCategoryForm) => {
      const { data, onSubmit, onClose, ...another } = options;
      const { name = '', id: categoryID } = data || {};
      // setImgURL(backgroundURL);
      setName(name);
      setData({ ...another, categoryID });
      callbackRef.current = {
        onSubmit,
        onClose,
      }
      originalDataRef.current = data;
      setOpen(true);
    }}]);
    return destroyEvents;
  }, []);

  const resetData = () => {
    setName('');
    // setImgFile(null);
    // setImgURL('');
    originalDataRef.current = {};
  };

  const handleOnClose = async() => {
    resetData();
    await callbackRef.current.onClose();
    setOpen(false);
  };

  const handleOnClick = async () => {
    setLoading(true);
    await callbackRef.current.onSubmit({ id: categoryID, name  });
    setLoading(false);
    resetData();
    setOpen(false);
  };

  return (
    <>
      <Modal
        title={title}
        okText={btnTxt}
        okButtonProps={{ disabled: btnDisabled }}
        cancelButtonProps={{ style: { background: '#EBF4FF', color: '#0068FF' }}}
        cancelText={"Thoát"}
        open={open}
        confirmLoading={loading}
        onOk={handleOnClick}
        onCancel={handleOnClose}
        centered={true}
        style={{ zIndex: 10000 }}
        zIndex={10000}
      >
        <Space style={{ width: '100%'}} size={12} direction="vertical">
          {/* <InputWrapper title="Hình ảnh">
            <ImgUpload
              onChange={(file) => setImgFile(file)}
              setImgURL={setImgURL}
              imgURL={imgURL}
            />
          </InputWrapper> */}
          <InputWrapper title="Tên danh mục" required={true}>
            <Input
              className=" -mr-3"
              placeholder="VD: Cà phê pha máy"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </InputWrapper>
        </Space>
      </Modal>
    </>
  );
};

export default CategoryForm;