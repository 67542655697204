import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'antd';
import { EVENTS, initEvents } from 'utils/eventEmitter';
import { IOpenConfirmPopup } from 'types';

const ConfirmPopup: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const { title, component = <></>, leftBtn = {}, rightBtn = {} } = data;
  const { text: leftBtnTxt } = leftBtn;
  const { text: rightBtnTxt } = rightBtn;
  const callbackRef = useRef<{ onRightClick: (...params: any) => any, onLeftClick: (...params: any) => any }>({
    onRightClick: () => {},
    onLeftClick: () => {}
  });

  useEffect(() => {
    const destroyEvents = initEvents([{ eventName: EVENTS.OPEN_POPUP_CONFIRM, listener: (options: IOpenConfirmPopup) => {
      const { leftBtn, rightBtn } = options;
      const { onClick: leftBtnOnClick = () => {} } = leftBtn || {};
      const { onClick: rightBtnOnClick = () => {} } = rightBtn || {};
      setData(options);
      callbackRef.current = {
        onRightClick: rightBtnOnClick,
        onLeftClick: leftBtnOnClick,
      }
      setOpen(true);
    }}]);
    return destroyEvents;
  }, []);

  const handleRightClick = async() => {
    setConfirmLoading(true);
    await callbackRef.current.onRightClick();
    setConfirmLoading(false);
    handleHide()
  };

  const handleHide = () => {
    setOpen(false);
    setData({});
    callbackRef.current = {
      onRightClick: () => {},
      onLeftClick: () => {},
    }
  }

  const handleLeftClick = async() => {
    setConfirmLoading(true);
    await callbackRef.current.onLeftClick();
    setConfirmLoading(false);
    handleHide();
  };

  return (
    <Modal
      title={title}
      okText={rightBtnTxt}
      cancelText={leftBtnTxt}
      open={open}
      confirmLoading={confirmLoading}
      onOk={handleRightClick}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleLeftClick}
      cancelButtonProps={{ style: { background: '#EBF4FF', color: '#0068FF' }}}
      maskClosable={false}
      closable={false}
      centered={true}
      keyboard={false}
    >
      {component}
    </Modal>
  );
};

export default ConfirmPopup;