import { useCallback } from 'react';
import { useNavigate as useNavigateHook } from 'react-router-dom';

const useNavigate = () => {
  const navigateToFunc = useNavigateHook();

  const navigateTo = useCallback((path, state = {}) => {
    navigateToFunc(path, { state });
  }, []);

  const navigateWithClear = useCallback((path, state = {}) => {
    navigateToFunc(path, { replace: true, state });
  }, []);

  const navigateBack = useCallback((step = 1) => {
    navigateToFunc(-step);
  }, []);

  return { navigateWithClear, navigateTo, navigateBack };
};

export default useNavigate;