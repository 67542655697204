import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  AuthContext,
  IAuthContext,
} from '../contexts/AuthContext';
import {
  TUser,
  getAccessToken,
  getRefreshToken,
  setTokenData,
  updateAccessToken,
  updateRefreshToken
} from '../utils/auth';
import { post, remove } from 'utils/request';
import { ROUTES } from 'constants/routes';
import useNavigate from 'hooks/useNavigate';
import { local } from 'utils/storage';
import { clearStorage } from 'utils/helpers';



const ACCESS_TOKEN_EXPIRES_TIME = 1000 * 60 * 1438; // 15 min

function AuthProvider({ children }: any) {
  const { navigateWithClear } = useNavigate();
  const localAccessToken = getAccessToken() || '';
  const localRefreshToken = getRefreshToken() || '';


  const handleLogin = (tokenData: Partial<TUser>) => {
    setTokenData(tokenData);

    navigateWithClear(ROUTES.RESTAURANTS.LIST);
  };

  const handleLogout = async () => {
    // Also remove user's refresh token from server
    const res = await remove(`/api/users/sign_out`, {refresh_token: localRefreshToken});
    const { ok, status } = res;
    if (ok && status === "ok") {
      clearStorage();
      local.removeObject("selectedRestaurant");
      window.location.href= '/login';
    }
  };

  const updateToken= async () =>  {
    const response = await post('/api/users/refresh_token', {refresh_token: localRefreshToken});

    if (response.ok && response.code === 200) {
      const  newAccessToken  = response.token;
      const newRefreshToken = response.refresh_token;
      updateAccessToken(newAccessToken);
      updateRefreshToken(newRefreshToken);
    } else {

      clearStorage();
      navigateWithClear('/login');
      window.location.reload();
    }
  }

  useEffect(() => {
    if (localRefreshToken){
      const timer = setTimeout(() => {
        updateToken();
      }, ACCESS_TOKEN_EXPIRES_TIME);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [localAccessToken]);

  const value = useMemo(() => ({
    token: localAccessToken,
    onLogin: handleLogin,
    onLogout: handleLogout,
  }), [localAccessToken]) as IAuthContext;

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
